/* EditPage.scss */
.edit-page {
    padding: 24px;
    max-width: 1200px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    .form-control {
        margin-bottom: 24px;
    }

    .radio-group {
        display: flex;
        justify-content: space-around;
        margin-bottom: 16px;
    }

    .form {
        display: flex;
        flex-direction: column;

        .text-field {
            margin-bottom: 16px;
        }
    }

    .button-group {
        display: flex;
        justify-content: flex-end;
        margin-top: 16px;

        .button {
            margin-left: 8px;
        }
    }
}
