/* src/components/DataTable.css */
.table-container {
    margin: 20px;
  }
  
  .custom-table th {
    background-color: #007bff;
    color: white;
    cursor: pointer;
    padding:0px 5px;
    font-size: 14px;
    font-weight: 500;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }
  
  .custom-table th:hover {
    background-color: #0056b3;
  }
  
  .custom-table td {
    background-color: #f8f9fa;
  }
  
  .custom-table tr:nth-child(even) td {
    background-color: #e9ecef;
  }
  
  .custom-table tr:hover td {
    background-color: #f1f3f5;
  }
  
  .pagination {
    margin-top: 20px;
  }
  