.edit-document-container {
    display: flex;
    flex-direction: row;
    margin: 20px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .edit-content {
    width: 45%;
    border: 1px solid #ddd;
    padding: 20px;
  }
  
  .pdf-viewer-container {
    width: 650px;
    height: auto;
    overflow: auto;
    border: 1px solid #ddd;
    padding: 20px;
  }
  
  .pagination-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
  
  .pagination-controls span {
    margin: 0 10px;
  }
  