.mainsearchoption {
    h3 {
        font-weight: 400;
    }
    .formdata {
        .inputdata {
            
        }
        .selectionfilter {
            font-size: 12px;
        }
        .parentselectionfilter {
            font-size: 12px;
            width: fit-content;
        }
        .parentselectionactionfilter {
            
        }
    }
    .mainsearchoption  {
        .searchresult {
            .result {
                .highlighted {
                    background-color: yellow;
                }
            }
        }
    }
    .highlighted {
        background-color: yellow;
    }
}
.css-xn6ruc {
    display: flex;
}
// src/SearchResults.scss
.search-results {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  
    .loading {
      font-size: 1.5em;
      color: #007bff;
    }
  
    .error {
      color: red;
      font-weight: bold;
    }
  
    .result-card {
      width: 80%;
      max-width: 1200px;
      background: #f9f9f9;
      border: 1px solid #ddd;
      border-radius: 5px;
      padding: 20px;
      margin: 20px 0;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s;
  
      &:hover {
        transform: translateY(-5px);
      }
  
      .filename {
        font-size: 1.2em;
        color: #333;
        margin-bottom: 10px;
      }
  
      .page-number {
        font-size: 1em;
        color: #555;
        margin-bottom: 15px;
      }
  
      .content {
        font-size: 1em;
        color: #333;
  
        .highlighted {
          background-color: yellow;
          font-weight: bold;
        }
      }
    }
  }
  
