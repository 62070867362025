/* CustomDialog.scss */
.custom-dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .custom-dialog {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  
    h2 {
      margin-bottom: 10px;
    }
  
    p {
      margin-bottom: 20px;
    }
  
    button {
      margin: 0 10px;
      padding: 10px 20px;
      border: none;
      border-radius: 4px;
      background-color: #007bff;
      color: #fff;
      cursor: pointer;
  
      &:last-child {
        background-color: #6c757d;
      }
  
      &:hover {
        opacity: 0.9;
      }
    }
  }
  