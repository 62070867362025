.container {
    font-family: Arial, sans-serif;
    margin: 20px;
  }
  
  .header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .inputgroup {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .inputgroup input {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 300px;
    margin-right: 10px;
  }
  
  .inputgroup button {
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .inputgroup button:hover {
    background-color: #0056b3;
  }
  
  .results {
    list-style: none;
    padding: 0;
  }
  
  .result-item {
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }
  
  .result-item strong {
    display: inline-block;
    margin-bottom: 5px;
  }
  
  .highlight, em {
    background-color: yellow;
  }
  