.view-log-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    
    h2 {
      margin-bottom: 20px;
      font-size: 2rem;
      color: #333;
    }
    
    .log-details-card {
      width: 100%;
      max-width: 1200px;
      padding: 20px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      border: none;
      
      .card-title {
        font-size: 1.5rem;
        font-weight: bold;
        color: #007bff;
      }
  
      .card-text {
        margin-bottom: 10px;
        font-size: 1rem;
        line-height: 1.5;
      }
      
      strong {
        color: #555;
      }
    }
    
    .back-button-container {
      margin-top: 20px;
      
      button {
        padding: 10px 20px;
        font-size: 1rem;
        background-color: #6c757d;
        border: none;
        color: white;
        transition: background-color 0.3s;
        
        &:hover {
          background-color: #5a6268;
        }
      }
    }
  }
  